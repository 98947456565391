import { useEffect, useState, useCallback } from 'react';
import SignIn from './components/SignIn/SignIn';
import { checkRegisteredEvents, deleteJwt, getAlert, getEmployeeInfo, getEvents, getJwt, getLoggedInuser } from './functions';
import List from './components/List/List';
import { IAlert, IEvent, IRegisteredEvents, ISPUserInfo, IUser } from './types';
import styles from './App.module.scss';
import Header from './components/Header/Header';
import Alert from './components/Alert/Alert';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Filters from './components/Filters/Filters';


const App = () => {

	dayjs.extend(utc);

	const [jwt, setJwt] = useState<string>("");
	const [events, setEvents] = useState<IEvent[]>([]);
	const [error, setError] = useState("");
	const [loggedInUser, setLoggedInUser] = useState<IUser | null>(null);
	const [alert, setAlert] = useState<IAlert>({ content: "", showAlert: false } as IAlert);
	const [selectedMonth, setSelectedMonth] = useState(dayjs().format("MM"));
	const [selectedYear, setSelectedYear] = useState(dayjs().format("YYYY"));
	const [loading, setLoading] = useState(false);
	const [userFound, setUserFound] = useState(false);
	const [spUser, setSpUser] = useState<ISPUserInfo | null>(null);
	const [registeredEvents, setRegisteredEvents] = useState<IRegisteredEvents[]>([]);

	//console.log("jwt", jwt)

	const getRegisteredEventsHandler = useCallback(() => {
		if (spUser?.fields.id && jwt) {
			checkRegisteredEvents(jwt, spUser.fields.id)
				.then((res: any) => setRegisteredEvents(res.data.registeredEvents))
				.catch(err => console.log(err))
		}
	}, [jwt,spUser]);

	/**
	 * Function to call the events
	 */
	const loadEvents = useCallback(() => {
		if (jwt) {
			setLoading(true);
			getEvents(jwt, selectedMonth, selectedYear)
				.then((res: any) => {
					setEvents([...res.data.data]);
					setLoading(false);
				})
				.catch(err => {
					console.log(err);
					setError(err.message);
					setLoading(false);
				});
		}
	}, [jwt, selectedMonth, selectedYear]);

	/**
	 * Empties all the states
	 */
	const clearStates = () => {
		deleteJwt();
		setJwt("");
		setEvents([]);
		setError("");
		setLoggedInUser({} as IUser);
	}

	/**
	 * Check if jwt exists on load
	 */
	useEffect(() => {
		if (getJwt()) {
			setJwt(getJwt() as string);
		}
	}, []);

	/** 
	 * Check if user is signed in, if they are, get all the stuff we need
	 */
	useEffect(() => {
		if (jwt) {
			getLoggedInuser(jwt)
				.then(res => setLoggedInUser(res))
				.catch(err => console.log(err));

			getAlert(jwt)
				.then(res => {
					setAlert(res);
				})
				.catch(err => console.log(err));
		}
	}, [jwt]);

	useEffect(() => {
		if (loggedInUser?.email && jwt) {
			getEmployeeInfo(jwt, loggedInUser.email)
				.then(res => {
					if (res.users.length) {
						const match = res.users.find(user => user.fields.Personal_x0020_Email_x0020_Addre.toString().toLowerCase().trim() === loggedInUser.email.toString().toLowerCase().trim());

						if (match) {
							setUserFound(true);
							setSpUser(match);
						}
					}
				})
				.catch(err => console.log(err))
		}
	}, [loggedInUser,jwt]);

	// useEffect(() => {
	// 	if(userFound) { 
	// 		loadEvents();
	// 		getRegisteredEventsHandler()
	// 	}
	// }, [userFound]);

	useEffect(() => {
		if (userFound) {
			loadEvents()
			getRegisteredEventsHandler();
		}
	}, [selectedMonth, selectedYear, jwt, userFound, getRegisteredEventsHandler, loadEvents]);


	return (
		<div className={styles.app}>

			<Header
				clearStates={clearStates}
				loggedInUser={loggedInUser} />

			<div className={styles.app__body}>
				{
					error ? (
						<div>{error}</div>
					) : (
						<>
							{
								jwt === "" ? (
									<>
										{jwt}
										<SignIn
											jwt={jwt}
											setJwt={setJwt} />
									</>
								) : (
									<>
										<Alert
											content={alert.content}
											visible={alert.showAlert} />
										{
											!userFound ? (
												<div>User not found</div>
											) : (
												!loading ? (
													<>
														<Filters
															selectedMonth={selectedMonth}
															selectedYear={selectedYear}
															setSelectedMonth={setSelectedMonth}
															setSelectedYear={setSelectedYear} />
														{
															spUser?.fields.id && (
																<List
																	getRegisteredEvents={getRegisteredEventsHandler}
																	registeredEvents={registeredEvents}
																	events={events}
																	jwt={jwt}
																	userId={spUser?.fields.id} />
															)
														}
													</>
												) : (
													<div>Loading, please wait...</div>
												)
											)
										}
									</>
								)
							}
						</>
					)
				}
			</div>
		</div>
	)
}

export default App;
