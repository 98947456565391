import styles from './Dropdown.module.scss';

interface IProps {
    options: {
        label: string
        key: string
    }[]
    label: string
    defaultValue: string
    onChange: (e: any) => void
}

const Dropdown = ({options, label, defaultValue, onChange}: IProps) => {

    return(
        <div className={styles.dropdown}>
            <div>{label}</div>
            <select className={styles.dropdown__select} defaultValue={defaultValue} onChange={(e) => onChange(e)}>
                {
                    options.map(item => (
                        <option key={item.key} value={item.key}>{item.label}</option>
                    ))
                }
            </select>
        </div>
    );
};

export default Dropdown;