import { IUser } from "../../types";
import styles from './User.module.scss';

interface IProps { 
    user: IUser | null
}

const User = ({user}: IProps) => {

    return(
        <div className={styles.user}>
            Signed in as <strong>{user?.email}</strong>
        </div>
    );
};

export default User;