import styles from './List.module.scss';
import { IEvent, IRegisteredEvents } from '../../types';
import Event from './Event';

interface IProps { 
    events: IEvent[]
    jwt: string
    userId: string
    registeredEvents: IRegisteredEvents[]
    getRegisteredEvents: () => void

}

const List = ({events, jwt, userId, registeredEvents, getRegisteredEvents}: IProps) => {
 
    return(
        <div className={styles.list}>
            {
                events.length ? events.map(event => (
                    <Event  
                    getRegisteredEvents={getRegisteredEvents}
                    key={event.id}
                     event={event} 
                     jwt={jwt}
                     registeredEvents={registeredEvents}
                     userId={userId} />
                )) : (
                    <div>There are no events for your selected date</div>
                )
            }
        </div>
    );
};

export default List;