import dayjs from 'dayjs';
import { IEvent, IRegisteredEvents, InterestType } from '../../types';
import Button from '../Button/Button';
import styles from './List.module.scss';
import { sendEventResponse } from '../../functions';
import { useEffect, useState, useCallback } from 'react';


interface IProps {
    event: IEvent
    jwt: string
    userId: string
    registeredEvents: IRegisteredEvents[]
    getRegisteredEvents: () => void

}

const Event = ({ event, jwt, userId, registeredEvents, getRegisteredEvents }: IProps) => {

    const [buttonResponse, setButtonResponse] = useState<InterestType>("");
    const [buttonlabel, setButtonLabel] = useState("");

    const toggleInterestHandler = (id: string, type: InterestType) => {
        if (buttonResponse === "unregister") {
            alert("If you are unavailable, please make contact with the Events Team.")
        }
        else {
            sendEventResponse(jwt, userId, id, type, Number(registeredEvents.find(x => x.eventId === event.id)?.listItemId) || null)
                .then(res => {
                    if (buttonResponse === "register") {
                        setButtonLabel("Unavailable for work");
                        setButtonResponse("unregister");
                        getRegisteredEvents()
                    } else {
                        setButtonResponse("register");
                        setButtonLabel("Available for work");
                        getRegisteredEvents()
                    }
                })
                .catch(err => console.log(err));
        }


    };


    const determineButtonResponse = useCallback(() => {
        if (registeredEvents.length) {
            const match = registeredEvents.find(x => x.eventId === event.id);
            if (match) {
                setButtonResponse("unregister");
                setButtonLabel("Unavailable for work");
            } else {
                setButtonResponse("register");
                setButtonLabel("Available for work");
            }
        } else {
            setButtonResponse("register");
            setButtonLabel("Available for work");
        }
    }, [registeredEvents,event]);

    useEffect(() => determineButtonResponse(), [determineButtonResponse])

    return (
        <div className={styles.list__item}>
            <div className={styles.list__title}>{event.fields.Title}</div>
            <div className={styles.list__ref}><strong>Ref</strong> {event.fields.EventReference}</div>
            <div className={styles.list__date}><strong>Date</strong> {dayjs(event.fields.Date).format("DD/MM/YYYY")}</div>
            <div className={styles.list__day}><strong>Day</strong> {event.fields.Day}</div>
            <div className={styles.list__address}><strong>Location</strong> {event.fields.Location}</div>
            <Button onClick={() => toggleInterestHandler(event.id, buttonResponse)} _className={buttonResponse}>{buttonlabel}</Button>
        </div>
    )

}
export default Event;