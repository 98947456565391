import React, { useState } from 'react';
import styles from './SignIn.module.scss';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { authenticateUser, storeJwt } from '../../functions';
import Error from '../Error/Error';

interface IProps { 
    jwt: string
    setJwt: React.Dispatch<React.SetStateAction<string>>
}

const SignIn = ({jwt, setJwt}: IProps): JSX.Element => {

    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ error, setError ] = useState("");

    const onSignInHandler = () => {
        authenticateUser(email, password)
        .then((res: any) => {
            if(res.data.jwt) {
                storeJwt(res.data.jwt);
                setJwt(res.data.jwt);
            }
        })
        .catch(err => {
            console.log(err);
            setError(err.message);
        });
    };

    const disableCheck = () => {
        if(!email || !password) { 
            return true;
        } else { 
            return false;
        }
    }

    return(
        <>
            {
                jwt === "" ? (
                    <div className={styles.auth}>
                        <div className={styles.auth__title}>Please sign in</div>
                        <Input 
                            label="Email Address"
                            required
                            value={email} 
                            type="email" 
                            onChange={setEmail} />

                        <Input 
                            label="Password"
                            required
                            value={password} 
                            type="password" 
                            onChange={setPassword} />   

                        <Button disabled={disableCheck()} onClick={() => onSignInHandler()}>Sign in</Button> 
                        {
                            error ? (
                                <Error>{error}</Error>
                            ) : null
                        }                      
                    </div>
                ) : null
            }
        </>
    );
};

export default SignIn