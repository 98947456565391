import Markdown from 'markdown-to-jsx';
import styles from './Alert.module.scss';

interface IProps {
    content: string
    visible: boolean
}

const Alert = ({content, visible}: IProps) => {

    return(
        <>
            {
                visible ? (
                    <section className={styles.alert}>
                        <Markdown>{content}</Markdown>
                    </section>
                ) : null
            }
        </>
    );
};

export default Alert;