import { SetStateAction } from 'react';
import styles from './Filters.module.scss';
import Dropdown from '../Dropdown/Dropdown';
import dayjs from 'dayjs';

interface IProps { 
    setSelectedMonth: React.Dispatch<SetStateAction<string>>
    setSelectedYear: React.Dispatch<SetStateAction<string>>
    selectedYear: string
    selectedMonth: string
}

const Filters = ({setSelectedMonth, selectedMonth, selectedYear, setSelectedYear}: IProps) => {


    return(
        <div className={styles.filters}>
            <Dropdown 
                onChange={(e: any) => {setSelectedMonth(e.target.value)}}
                defaultValue={selectedMonth.toString()}
                label='Month' 
                options={[
                    {
                        key: "01",
                        label: "January"
                    },
                    {
                        key: "02",
                        label: "February"
                    },
                    {
                        key: "03",
                        label: "March"
                    },
                    {
                        key: "04",
                        label: "April"
                    },
                    {
                        key: "05",
                        label: "May"
                    },
                    {
                        key: "06",
                        label: "June"
                    },
                    {
                        key: "07",
                        label: "July"
                    },
                    {
                        key: "08",
                        label: "August"
                    },
                    {
                        key: "09",
                        label: "September"
                    },
                    {
                        key: "10",
                        label: "October"
                    },
                    {
                        key: "11",
                        label: "November"
                    },
                    {
                        key: "12",
                        label: "December"
                    }
                ]} />
            <Dropdown 
                onChange={(e: any) => setSelectedYear(e.target.value)}
                defaultValue={selectedYear.toString()}
                label='Year'
                options={[                                   
                    {
                        key: dayjs().year().toString(),
                        label: dayjs().year().toString()
                    },
                    {
                        key: (dayjs().year() +1).toString(),
                        label: (dayjs().year() +1).toString()
                    },
                    {
                        key: (dayjs().year() +2).toString(),
                        label: (dayjs().year() +2).toString()
                    }                    
                ]} />
        </div>
    );
};

export default Filters;