import styles from './Header.module.scss';
import Logo from '../../images/logo.jpg';
import { getJwt } from '../../functions';
import User from '../User/User';
import { IUser } from '../../types';


interface IProps { 
    clearStates: () => void
    loggedInUser: IUser | null
}

const Header = ({clearStates, loggedInUser}: IProps) => {

    const signOutClickHandler = () => {
        clearStates()
    }

    return(
        <header className={styles.header}>
            <div className={styles.header__inner}>
                <div className={styles.header__logo}>
                    <img src={Logo} alt="logo" />
                </div>

               
                {
                    getJwt() ? (
                        <div className={styles.header__user}>
                            <User 
                                user={loggedInUser} /> 
                            <div className={styles.header__logout} onClick={() => signOutClickHandler()}>
                                Sign out
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </header>
    );
};

export default Header;