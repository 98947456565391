import styles from './Error.module.scss';

interface IProps { 
    children: React.ReactNode
}

const Error = ({children}: IProps) => {

    return(
        <div className={styles.error}>
            {children}
        </div>   
    );
};

export default Error;