// export const authApiPath = "http://localhost:1337/api/auth/local";
// export const getEventsApiPath = "http://localhost:1337/api/get-events";
// export const sendEventResponseApiPath = "http://localhost:1337/api/update-event-interest";
// export const loggedInUserApiPath = "http://localhost:1337/api/users/me";

export const authApiPath = "https://constant-security-api.azurewebsites.net/api/auth/local";
export const getEventsApiPath = "https://constant-security-api.azurewebsites.net/api/get-events";
export const sendEventResponseApiPath = "https://constant-security-api.azurewebsites.net/api/update-event-interest";
export const loggedInUserApiPath = "https://constant-security-api.azurewebsites.net/api/users/me";
export const alertsApiPath = "https://constant-security-api.azurewebsites.net/api/alert";
export const employeeLookupApiPath = "https://constant-security-api.azurewebsites.net/api/employee-lookup";
export const checkRegisteredEventsApiPath = "https://constant-security-api.azurewebsites.net/api/check-registered-events";