import React from 'react';
import styles from './Button.module.scss';

interface IProps { 
    children: React.ReactNode
    onClick: () => void
    disabled?: boolean
    _className?:string
}

const Button = ({children, onClick, disabled,_className}: IProps) => {
    return(
        <button disabled={disabled} className={ _className==="register"? styles.button: styles.unregister} onClick={() => onClick()}>
            <span>{children}</span>
        </button>
    );
};

export default Button