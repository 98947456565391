import axios from "axios";
import { alertsApiPath, authApiPath, checkRegisteredEventsApiPath, employeeLookupApiPath, getEventsApiPath, loggedInUserApiPath, sendEventResponseApiPath } from "./routes";
import { IAlert, ISPUser, IUser, InterestType } from "./types";

/**
 * Auth the user against strapi
 * @param email 
 * @param password 
 * @returns user's JWT
 */
export const authenticateUser = async (email: string, password: string) => {
    return new Promise((resolve, reject) => {
        axios.post(authApiPath, {identifier: email, password: password})
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
};

/**
 * Stores the users JWT in local storage under the name of eventmanagementuserjwt
 * @param jwt 
 */
export const storeJwt = (jwt: string) => {
    localStorage.setItem("eventmanagementuserjwt", jwt);
};

/**
 * Get the users JWT if it exists in local storage
 * @returns User's JWT
 */
export const getJwt = () => {
    return localStorage.getItem("eventmanagementuserjwt");
};

/**
 * Deletes the users JWT from local storage
 * @returns function to delete the users JWT
 */
export const deleteJwt = () => {
    return localStorage.removeItem("eventmanagementuserjwt");
}

/**
 * Calls Strapi to get the events from SharePoint
 * @param jwt 
 * @returns IEvent[]
 */
export const getEvents = (jwt: string, month: string, year: string) => {
    return new Promise((resolve, reject) => {
        axios.post(getEventsApiPath, {month: month, year: year}, { headers: { Authorization: `bearer ${jwt}`}})
        .then(res => resolve(res))
        .catch(err => reject(err))
    });
};

/**
 * Create list item with the users response type
 * @param jwt 
 * @param type registerInterest or unregisterInterest
 * @returns ???
 */
export const sendEventResponse = (jwt: string, user: string, event: string, type: InterestType, listItemId?: number | null) => {
    return new Promise((resolve, reject) => {
        axios.post(sendEventResponseApiPath, {user: user, event: event, type: type, listItemId: listItemId}, {headers: {Authorization: `bearer ${jwt}`}})
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
};

/**
 * Get the current logged in user details, such as name, email, etc
 * @param jwt jwt string
 * @returns Logged in user details
 */
export const getLoggedInuser = (jwt: string) => {
    return new Promise<IUser>((resolve, reject) => {
        axios.get(loggedInUserApiPath, {headers: {Authorization: `bearer ${jwt}`}})
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
};

/**
 * Get the alert from Strapi, if there is one
 * @param jwt jwt string
 * @returns Returns the alert information
 */
export const getAlert = (jwt: string) => {
    return new Promise<IAlert>((resolve, reject) => {
        axios.get(alertsApiPath, {headers: {Authorization: `bearer ${jwt}`}})
        .then(res => {
            resolve(res.data.data.attributes)
        })
        .catch(err => reject(err));
    });    
};

/**
 * Lookup the curent user in SharePoint to get their lookup ID
 * @param jwt jwt string
 * @returns Returns the current users information from SharePoint
 */
export const getEmployeeInfo = (jwt: string, email: string) => {
    return new Promise<ISPUser>((resolve, reject) => {
        axios.post(employeeLookupApiPath, {email: email}, {headers: {Authorization: `bearer ${jwt}`}})
        .then(res => {
            resolve(res.data)
        })
        .catch(err => reject(err));
    });    
};

/**
 * Check if the current user has registered to any events
 * @param jwt jwt string
 * @param user user ID
 * @returns Registered events
 */
export const checkRegisteredEvents = (jwt: string, user: string) => {
    return new Promise((resolve, reject) => {
        axios.post(checkRegisteredEventsApiPath, {user: user}, { headers: { Authorization: `bearer ${jwt}`}})
        .then(res => resolve(res))
        .catch(err => reject(err))
    });
};
