import React from 'react';
import styles from './Input.module.scss';

interface IProps { 
    value: string
    type: string
    onChange: React.Dispatch<React.SetStateAction<string>>
    required: boolean
    label: string
}

const Input = ({value, type, onChange, required, label}: IProps) => {

    return(
        <div>
            <label>{label}</label>
            <input 
                className={styles.input}
                value={value} 
                type={type} 
                required={required}
                onChange={(e) => onChange(e.target.value)} />
        </div>
    );
};

export default Input